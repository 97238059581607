<template>
  <div class="container">
    <Header />
    <div class="main">
      <el-row>
        <el-col :span="16">
          <el-row class="date" style="display: flex; flex-direction: row; align-items: center">
            <el-col :span="6">
              <div>{{ $t('checkin') }}</div>
              <div>{{ reservation.arrival }}</div>
            </el-col>
            <el-col :span="6"><i class="el-icon-arrow-right"></i></el-col>
            <el-col :span="6">
              <div>{{ $t('checkout') }}</div>
              <div>{{ reservation.departure }}</div>
            </el-col>
          </el-row>

          <el-row class="cancellation">
            <ul>
              <li v-for="(item, index) of cancellationPolicies" :key="index" style="color: red;">
                {{ item.description }}
              </li>
            </ul>
          </el-row>

          <div class="reservation">
            <div class="block">
              <h2>{{ $t('yourInformation') }}</h2>
              <el-form ref="form" :model="reservation" :rules="rules" label-width="160px" label-position="left" style="max-width: 400px;">
                <el-form-item :label="$t('mobileNumber')" prop="contact_number">
                  <el-input
                    v-model="reservation.contact_number"
                    :placeholder="$t('yourMobileNumber')"
                    maxlength="50"
                  />
                </el-form-item>
                <el-form-item :label="$t('email')" prop="email">
                  <el-input
                    v-model="reservation.email"
                    :placeholder="$t('yourEmail')"
                    maxlength="50"
                  />

                </el-form-item>
                <p style="color: red;">{{ $t('emailMessage') }}</p>

                <el-form-item :label="$t('lastName')" prop="last">
                  <el-input
                    v-model="reservation.last"
                    :placeholder="$t('yourLastName')"
                    maxlength="50"
                  />
                </el-form-item>
                <el-form-item :label="$t('firstName')" prop="first">
                  <el-input
                    v-model="reservation.first"
                    :placeholder="$t('yourFirstName')"
                    maxlength="50"
                  />
                </el-form-item>
                <el-form-item :label="$t('gender')" prop="gender">
                  <el-radio-group v-model="reservation.gender">
                    <el-radio label="Male">{{ $t('Male') }}</el-radio>
                    <el-radio label="Female">{{ $t('Female') }}</el-radio>
                  </el-radio-group>
                </el-form-item>
                <el-form-item :label="$t('idType')" prop="id_type">
                  <el-select
                    v-model="reservation.id_type"
                  >
                    <el-option
                      v-for="item of idTypes"
                      :key="item.code"
                      :value="item.code"
                      :label="item | getLabel"
                      :disabled="!item.enable"
                    />
                  </el-select>
                </el-form-item>
                <el-form-item :label="$t('idNumber')" prop="id_number">
                  <el-input
                    v-model="reservation.id_number"
                    :placeholder="$t('yourIdNumber')"
                    maxlength="50"
                  />
                </el-form-item>
                <el-form-item :label="$t('accompany')" prop="accompany">
                  <el-input
                    v-model="reservation.accompany"
                    :placeholder="$t('accompanyNames')"
                    maxlength="50"
                  />
                </el-form-item>
                <el-form-item :label="$t('arrivalTime')" prop="arrival_time">
                  <el-select
                    v-model="reservation.arrival_time"
                  >
                    <el-option
                      v-for="item in reservationTimes"
                      :key="item.code"
                      :value="item.code"
                      :label="item | getLabel"
                    />
                  </el-select>
                </el-form-item>
                <el-form-item :label="$t('departureTime')" prop="departure_time">
                  <el-select
                    v-model="reservation.departure_time"
                  >
                    <el-option
                      v-for="item in reservationTimes"
                      :key="item.code"
                      :value="item.code"
                      :label="item | getLabel"
                    />
                  </el-select>
                </el-form-item>
              </el-form>
            </div>

            <div class="block">
              <h2>{{ $t('remark') }}</h2>
              <el-input
                type="textarea"
                v-model="reservation.remark"
                rows="3"
                resize="none"
                maxlength="100"
                :placeholder="$t('reservationRemark')"
                show-word-limit
                style="border: 1px solid #dedede;"
              />
            </div>
            <el-divider />

            <div style="display: flex; flex-direction: row; align-items: center; padding-bottom: 20px;">
              <el-checkbox v-model="accepted" shape="square">{{ $t('readAndAccept') }}</el-checkbox>
              <span class="policy" @click="showPolicy = true">《{{ $t('cancellationPolicies') }}》</span>
            </div>
            <van-popup v-model="showPolicy" closeable :style="{ width: '400px' }">
              <div style="padding: 40px 20px 20px 20px;">
                <h4 style="text-align: center;">
                  {{ $t('cancellationPolicies') }}:
                </h4>
                <ul>
                  <li v-for="(item, index) of cancellationPolicies" :key="index">
                    {{ item.description }}
                  </li>
                </ul>
              </div>
            </van-popup>
          </div>

        </el-col>

        <el-col :span="8" style="padding: 20px;">
          <el-row>
            <el-image style="width: 100%" lazy fit="cover" :src="hotel.images && hotel.images[0] || '#'" />
          </el-row>

          <el-row>
            <div class="hotelname">{{ hotel.name }}</div>
          </el-row>

          <el-row>
            <div class="hoteladdress"><span class="iconfont icon-iconlocation" /> {{ hotel.address }}</div>
          </el-row>

          <el-row class="rate">
            <el-col :span="12">{{ $t('finalPrice') }}</el-col>
            <el-col :span="12" style="text-align: right;">{{ hotel.currency_code }}{{ reservation.total_amount | formatNumber }}</el-col>
          </el-row>

          <el-divider />
          <div>
            <div>{{ $t('detail') }}</div>
            <el-row
              v-for="(item, index) of details"
              :key="index"
              style="color: #5c5c5c; padding: 6px 0;"
            >
              <el-col :span="12">{{ item.reservation_date }}</el-col>
              <el-col :span="12" style="text-align: right;">{{ hotel.currency_code }} {{ item.rate_amount | formatNumber }}</el-col>
            </el-row>
          </div>
          <el-divider />

          <el-button
            type="warning"
            size="large"
            style="width: 100%;"
            @click="save"
            :loading="loading"
          >
            {{ $t('bookNow') }}
          </el-button>
        </el-col>
      </el-row>

      <div style="flex: 1;"></div>
      <Footer/>

    </div>
  </div>
</template>

<script>
import _ from 'lodash'
import dayjs from '@/utils/dayjs'
import Header from '@/components/Header'
import Hotel from '@/models/hotel'
import Reservation from '@/models/reservation'
import RoomType from '@/models/roomType'
import { getDates, reservationTimes } from '@/utils'
import isEmail from 'validator/lib/isEmail'
import isMobilePhone from 'validator/lib/isMobilePhone'
import isIdentityCard from 'validator/lib/isIdentityCard'
import { Notify } from 'vant'

export default {
  name: 'Reservation',
  components: {
    Header
  },
  data () {
    return {
      showGender: false,
      showIdType: false,
      showPolicy: false,
      showDetail: false,
      accepted: true,
      loading: false,
      errors: new Array(6).fill({}).map(e => ({ error: false, message: null })),
      hotel: new Hotel(),
      roomType: new RoomType(),
      cancellationPolicies: [],
      idTypes: [],
      reservationTimes,
      reservation: new Reservation({
        hotel_id: this.$route.params.hotelId,
        room_type_id: this.$route.params.roomTypeId,
        arrival: this.$route.params.arrival,
        departure: this.$route.params.departure,
        guests: this.$route.params.guests
      })
    }
  },
  computed: {
    nights () {
      if (this.reservation.arrival && this.reservation.departure) {
        return dayjs(this.reservation.departure).diff(dayjs(this.reservation.arrival), 'day')
      }

      return 0
    },
    genders () {
      return [
        { code: 'Male', name: this.$t('Male') },
        { code: 'Female', name: this.$t('Female') }
      ]
    },
    idTypeLabel () {
      if (this.reservation.id_type) {
        const idType = _.find(this.idTypes, { code: this.reservation.id_type })
        if (idType) {
          return idType.name
        }
      }

      return this.reservation.id_type
    },
    details () {
      const dates = getDates(this.reservation.arrival, this.reservation.departure)
      if (dates.length > 1) {
        dates.pop()
      }

      return dates.map(e => ({
        reservation_date: e,
        rate_amount: this.roomType.rate_amount
      }))
    },
    rules () {
      return {
        contact_number: [
          { required: true, message: this.$t('contact number is required'), trigger: 'blur' },
          {
            validator: (rule, value, callback) => {
              if (!isMobilePhone(value)) {
                callback(new Error(this.$t('invalid mobile phone number')))
              } else {
                callback()
              }
            },
            trigger: 'blur'
          }
        ],
        email: [
          { required: true, message: this.$t('email is required'), trigger: 'blur' },
          {
            validator: (rule, value, callback) => {
              if (!isEmail(value)) {
                callback(new Error(this.$t('invalid email format')))
              } else {
                callback()
              }
            },
            trigger: 'blur'
          }
        ],
        last: [{ required: true, message: this.$t('last name is required'), trigger: 'blur' }],
        first: [{ required: true, message: this.$t('first name is required'), trigger: 'blur' }],
        id_number: [
          { required: true, message: this.$t('id number is required'), trigger: 'blur' },
          {
            validator: (rule, value, callback) => {
              if (this.reservation.id_type === 'ID' && !isIdentityCard(value, ['zh-CN'])) {
                callback(new Error(this.$t('invalid id number')))
              } else {
                callback()
              }
            },
            trigger: 'blur'
          }
        ],
        arrival_time: [{ required: true, message: this.$t('arrival time is required'), trigger: 'blur' }],
        departure_time: [{ required: true, message: this.$t('departure time is required'), trigger: 'blur' }]
      }
    }
  },
  async mounted () {
    try {
      const response = await this.$axios.get('/id-type', {
        params: {
          sort: 'sequence',
          search: 'enable==1'
        }
      })
      this.idTypes = response.data.rows
      const res = await this.$axios.get(`/invitation/hotel-detail/${this.$route.params.hotelId}/room-type-detail/${this.$route.params.roomTypeId}`)
      Object.assign(this.hotel, res.data.hotel)
      Object.assign(this.roomType, res.data.roomType)
      this.cancellationPolicies = res.data.cancellationPolicies
      this.reservation.total_amount = (parseInt(this.nights) * this.roomType.rate_amount).toFixed(2)
    } catch (e) {
      Notify(e.message)
    }
  },
  methods: {
    async save () {
      await this.$refs.form.validate()
      if (!this.accepted) {
        Notify(this.$t('please read the cancellation policy carefully and check accepted'))
        return
      }

      if (this.nights < 2) {
        Notify(this.$t('reservationNightsMessage'))
        return
      }
      // 提交预订
      try {
        this.loading = true
        const res = await this.$axios.post('/reservation', this.reservation)

        this.$router.push({
          name: 'Payment',
          params: { reservationId: res.data.id }
        })
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style lang="less" scoped>
.main {
  padding-top: 64px;
  max-width: 1200px;
  margin: 0 auto;
}

.reservation {
  padding: 1rem;

  .el-select {
    width: 100%;
  }
}

.date {
  padding: 20px 0;
  .el-col {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    & > div:last-child {
      margin-top: 20px;
    }
  }
}

.hotelname {
  padding-top: 20px;
  font-size: 1.5rem;
  color: #333333;
}

.hoteladdress {
  padding: 20px 0;
  color: #5c5c5c;
}
.room-type-name {
  margin-top: .3rem;
  font-size: 1rem;
  color: #2766ba;
}

.breakfast {
  color: #5c5c5c;
  margin-top: .3rem;
}

.policy {
  color: #2766ba;
}

ul {
  list-style: inside;
  li {
    color: #5c5c5c;
    margin-bottom: 10px;
  }
}

.padding-bottom.van-popup {
  bottom: 50px;
}
</style>
